import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";

export const Hod = () => {
    const [hod_a, setHod_a] = useState();
    const [active, setActive] = useState({
        year: null,
        month: null,
        photos: []
    })
    const [photos, setPhotos] = useState([])


    useEffect(() => {
        const headers = { 'Content-Type': 'application/json' }
        fetch(process.env.REACT_APP_BACKEND_URL + "/hod_react.php", headers)
            .then(res => res.json())
            .then((result) => {
                setHod_a(result)

                let year = Object.keys(result.struct)[Object.keys(result.struct).length - 1]
                let month = Object.keys(result.struct[year]).sort().reverse()[0] //Object.keys(result.struct[year]).length - 1
                let photos = result.photos[year + "_" + month]

                setActive({
                    year: year,
                    month: month,
                    photos: photos
                })

                setPhotos(photos)
            })
    }, [])



    const yearClick = (e) => {
        e.preventDefault()
        let tmp = active
        console.log(tmp)
        tmp.year = e.currentTarget.getAttribute('data')
        tmp.month = Object.keys(hod_a.struct[tmp.year])[Object.keys(hod_a.struct[tmp.year]).length - 1]
        tmp.photos = hod_a.photos[tmp.year + "_" + tmp.month]

        setActive(tmp)
        setPhotos(tmp.photos)
    }
    const monthClick = (e) => {
        e.preventDefault()
        let tmp = active
        tmp.month = e.currentTarget.getAttribute('data')
        tmp.photos = hod_a.photos[tmp.year + "_" + tmp.month]
        console.log(hod_a.photos)
        setActive(() => tmp)
        setPhotos(tmp.photos)
    }

    const monthName = (month) => {
        let name = ''
        switch (month) {
            case '01':
                name = "Январь"
                break
            case '02':
                name = "Февраль"
                break
            case '03':
                name = "Март"
                break
            case '04':
                name = "Апрель"
                break
            case '05':
                name = "Май"
                break
            case '06':
                name = "Июнь"
                break
            case '07':
                name = "Июль"
                break
            case '08':
                name = "Август"
                break
            case '09':
                name = "Сентябрь"
                break
            case '10':
                name = "Октябрь"
                break
            case '11':
                name = "Ноябрь"
                break
            default:
                name = "Декабрь"
                break
        }
        return name
    }

    const settings = {
        speed: 400,
        spaceBetween: 12,
        loop: false,
        autoHeight: true,
    }

    if (hod_a === null || active.year === null) {
        return <div>loading</div>
    }


    return (
        <section class="hod plr">
            <div class="wmain">
                <div class="tm">Ход строительства</div>
                <div class="hod_nav">
                    <div class="hod_nav_l">&nbsp;</div>
                    <div class="hod_mounth">
                        {Object.keys(hod_a.struct[active.year]).sort().reverse().map((month) => {
                            return <div onClick={monthClick} data={month} className={active.month === month ? "act" : ""} >{monthName(month)}</div>
                        })}
                    </div>
                    <div class="hod_year">
                        {Object.keys(hod_a.struct).reverse().map((year) => {
                            return <div className={active.year === year ? "act" : ""} onClick={yearClick} data={year}>{year}</div>
                        })}

                    </div>
                </div>
                <LightgalleryProvider style={{ display: "none" }}>
                    <Swiper
                        className='hod_slider'
                        {...settings}
                        modules={[Navigation]}
                        navigation
                        breakpoints={{
                            520: {
                                slidesPerView: 'auto',
                                spaceBetween: 22,
                            },
                        }}
                        style={{ position: "relative" }}
                    >
                        {photos && photos.map((photo) => {
                            if (photo.indexOf("m.jpg") != -1) {
                                return <SwiperSlide>
                                    <LightgalleryItem group={"group"} src={process.env.REACT_APP_BACKEND_URL + "/" + photo.replace("m.jpg", ".jpg")} image={process.env.REACT_APP_BACKEND_URL + "/" + photo.replace(".m.jpg", ".jpg")} thumb={process.env.REACT_APP_BACKEND_URL + "/" + photo} >

                                        <div style={{ cursor: "pointer" }} className="hod_slide">
                                            <img src={process.env.REACT_APP_BACKEND_URL + "/" + photo} />
                                        </div>
                                    </LightgalleryItem>
                                </ SwiperSlide>
                            }
                        })
                        }
                        <div class="preim_slider_nav">
                            <div class="swiper-button-prev">
                                <svg width="66.008057" height="26.643555" viewBox="0 0 66.0081 26.6436" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnXxlink="http://www.w3.org/1999/xlink">
                                    <path id="Arrow 4" d="M6.83 11.32L14.73 3.42C15.52 2.63 15.52 1.38 14.73 0.59C13.94 -0.2 12.69 -0.2 11.9 0.59L0.59 11.9C-0.2 12.69 -0.2 13.94 0.59 14.73L11.9 26.04C12.69 26.84 13.94 26.84 14.73 26.04C15.52 25.25 15.52 24.01 14.73 23.22L6.83 15.32L64 15.32C65.12 15.32 66 14.44 66 13.32C66 12.2 65.12 11.32 64 11.32L6.83 11.32Z" fill="#1E1E1E" fill-opacity="1.000000" fill-rule="evenodd" />
                                </svg>
                            </div>
                            <div class="swiper-button-next">
                                <svg width="66.008057" height="26.643555" viewBox="0 0 66.0081 26.6436" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnXxlink="http://www.w3.org/1999/xlink">
                                    <path id="Arrow 3" d="M59.17 11.32L51.27 3.42C50.48 2.63 50.48 1.38 51.27 0.59C52.06 -0.2 53.3 -0.2 54.1 0.59L65.41 11.9C66.2 12.69 66.2 13.94 65.41 14.73L54.1 26.04C53.3 26.84 52.06 26.84 51.27 26.04C50.48 25.25 50.48 24.01 51.27 23.22L59.17 15.32L2 15.32C0.87 15.32 0 14.44 0 13.32C0 12.2 0.87 11.32 2 11.32L59.17 11.32Z" fill="#1E1E1E" fill-opacity="1.000000" fill-rule="evenodd" />
                                </svg>
                            </div>
                        </div>
                    </Swiper>
                </LightgalleryProvider>
            </div>
        </section>

    )
}
