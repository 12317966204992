import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useSendForm } from "../hooks/send-from.hook";
import { Form } from "./form";
import { LightgalleryItem, LightgalleryProvider } from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";

export const ModalC = ({ title, background, formClassName, position, hidden, flat, fields, celtype, btnTitle, success, personal, template, callback, close }) => {
  const sendForm = useSendForm()
  const [successShow, setSuccessShow] = useState(false)
  const successCallback = () => {
    setSuccessShow(true)
  }
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: position,
      });
    }, 0)
  }, [])


  useEffect(() => {
    setTimeout(() => {
      document.querySelector('.modal').addEventListener('click', function (event) {
        let containingElement = document.querySelector('.pu_inner');
        if (!containingElement) {
          return
        }

        if (containingElement.contains(event.target)) {
          //console.log('click inside form')
        } else {
          //console.log('click outside form')
          close()
        }
      });
    }, 200)
  }, [])



  if (success || successShow) {
    return <Modal isOpen={true} toggle={() => { setModalState(null) }}>
      <ModalBody>
        <div key="ok" className="pu_rgba plr">
          <div className="pu_table">
            <div className="pu_cell">
              <div className="pu_inner pu_good" id="popup_ok" style={{ display: "block" }}>
                <div className="closeform" onClick={(e) => { e.preventDefault(); close() }}><svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M22.8945 1.66618L2.34196 22.2188L0.894531 20.7713L21.4471 0.21875L22.8945 1.66618Z" fill="#AB0F14" /><path fillRule="evenodd" clipRule="evenodd" d="M2.34196 0.21875L22.8945 20.7713L21.4471 22.2188L0.894531 1.66618L2.34196 0.21875Z" fill="#AB0F14" /></svg></div>
                <div className="tm_main">
                  <div className="tm">
                    <span>Спасибо</span>
                    <div className="tm_table">
                      <div className="tm_cell">
                        <img data-src="img/text_spasibo.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pu_title_info">
                  В ближайшее время с вами свяжется наш специалист.
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  }

  if (flat) {
    const caseTitle = (classFlat) => {
      switch (classFlat) {
        case 0: return "Квартира-студия"
        case 1: return "Однокомнатная квартира"
        case 2: return "Двухкомнатная квартира"
        case 3: return "Трехкомнатная квартира"
      }
    }
    return (
      <Modal isOpen={true} toggle={() => { setModalState(null) }}>
        <div className="pu_rgba plr">
          <div className="pu_table">
            <div className="pu_cell">
              <div className="pu_inner pu_flat" style={{ "background": background, display: "block" }}>
                <div className="closeform" onClick={(e) => { e.preventDefault(); close() }}>
                  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M22.8945 1.66618L2.34196 22.2188L0.894531 20.7713L21.4471 0.21875L22.8945 1.66618Z" fill="#AB0F14" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M2.34196 0.21875L22.8945 20.7713L21.4471 22.2188L0.894531 1.66618L2.34196 0.21875Z" fill="#AB0F14" />
                  </svg>
                </div>
                <div className="pu_title">{caseTitle(flat.rooms)}</div>
                <div class="pu_flat_inner">
                  <div class="pu_flat_img">
                    <LightgalleryProvider style={{ display: "none" }}>
                      <LightgalleryItem group={"group"} src={flat.img} image={flat.img} thumb={flat.img} >
                        <img src={flat.img} />
                      </LightgalleryItem>
                    </LightgalleryProvider>
                  </div>
                  <Form
                    fields={fields}
                    hidden={hidden + ". " + caseTitle(flat.rooms) + "; Площадь:" + flat.full_area + "кв.м; Id квартиры в crm:" + flat.id}
                    btnTitle={btnTitle}
                    celtype={celtype}
                    template={template}
                    callback={() => successCallback()}
                    personal={true}
                    formClassName={formClassName}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <Modal isOpen={true} toggle={() => { setModalState(null) }}>
      <ModalBody>
        <div className="pu_rgba plr">
          <div className="pu_table">
            <div className="pu_cell">
              <div className={formClassName == "pu_ipoteka" ? "pu_inner pu_ipoteka" : "pu_inner"} style={{ "background": background, display: "block" }}>
                <div class="closeform" onClick={(e) => { e.preventDefault(); close() }}>
                  <svg width="28.969727" height="28.309265" viewBox="0 0 28.9697 28.3093" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <path id="Line 20" d="M2.66 2L26.96 26.3" stroke="#FFFFFF" stroke-opacity="1.000000" stroke-width="4.000000" stroke-linecap="round" />
                    <path id="Line 21" d="M26.3 2L2 26.3" stroke="#FFFFFF" stroke-opacity="1.000000" stroke-width="4.000000" stroke-linecap="round" />
                  </svg>
                </div>
                <div className="pu_title">{title}</div>
                <Form
                  fields={fields}
                  hidden={hidden}
                  btnTitle={btnTitle}
                  celtype={celtype}
                  template={template}
                  callback={() => successCallback()}
                  personal={true}
                  formClassName={formClassName}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalC

