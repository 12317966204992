import { useState } from "react"

const SelectInput = ({ field }) => {
    const [selectedOption, setSelectedOption] = useState(null)

    return (
        <label class="select_style">
            <select onChange={(e) => { setSelectedOption(e.target.value) }}>
                <option value="">{field.placeholder}</option>
                {field.options && field.options.map((option) => {
                    return <option value={option}>{option}</option>
                })}
            </select>
            <input type="hidden" className="dop-info" data={field.placeholder} value={selectedOption} />
        </label>
    )
}

export default SelectInput