import { useState } from "react";

export const PhoneInput = ({noRow}) => {
    const [phone, setPhone] = useState("")

    const phoneChange = (e) => {
        let phone = e.target.value.replace(/\D/g, '')
        let result = "+7 ("
        for (let i = 0; i < phone.length; i++) {
            if (i == 0) {
                if (parseInt(phone[i]) === 8) {
                    result = '+7 (';
                } else if (parseInt(phone[i]) === 9) {
                    result = '+7 (9';

                } else if (parseInt(phone[i]) === 7) {
                    result = '+7 (';
                } else {
                    result = '';
                }
                continue;
            }
            if (i === 9) {
                result += '-' + phone[i];
                continue;
            }

            if (i === 7) {
                result += '-' + phone[i];
                continue;
            }

            if (i === 4) {
                result += ') ' + phone[i];
                continue;
            }
            result += phone[i]
        }
        setPhone(result)
    }

    return (
       <input type="tel" name="phone" req="y" className="ym-record-keys in_style" onChange={phoneChange} placeholder={"Ваш телефон"} value={phone} />
    )
}