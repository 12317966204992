import React, { useEffect, useState } from 'react'
import { useContext } from "react"
import { BlocksContext } from "../context/blocksContext"
import ModalC from './modal'
import { Form } from './form'
import { Swiper, SwiperSlide } from 'swiper/react';


export const Header = () => {
    const [swiper, setSwiper] = useState()
    const blocks = useContext(BlocksContext)

    useEffect(() => {
        fetch(process.env.REACT_APP_BACKEND_URL + "/action/action.json").then(res => res.json()).then((resp) => {
            setActionDate(resp.data)
        })
    }, [])

    const clickMenu = (e) => {
        e.preventDefault()
        let popup = e.currentTarget.getAttribute("href")

        if (blocks.blocks < 14) {
            blocks.setBlocks(14)
            setTimeout(() => {

                window.scrollTo({
                    top: document.querySelector("#" + popup).offsetTop,
                    behavior: "smooth"
                })

            }, 1400)

        } else {
            window.scrollTo({
                top: document.querySelector("#" + popup).offsetTop,
                behavior: "smooth"
            })
        }

        setMobileOpen(false)
    }

    useEffect(() => {
        if (swiper) {
            setTimeout(() => {
                swiper.slideNext()
            }, 30000)
        }
    }, [swiper])

    return (<React.Fragment>
        <div className="header plr">
            <div className="header_inner">
                <h1>
                    ЖК ОЛИМП <br />Квартиры в Георгиевске от 3,67 млн руб
                </h1>
                <div className="head_inf">
                    Рассрочка от застройщика <br />до конца строительства
                </div>
                <div className="align_center">
                    <a className="btn_main" href="#" onClick={(e)=>{e.preventDefault(); Marquiz.showModal('66e2e565518bf90026c50892')}}>Получить подборку квартир</a>
                </div>
            </div>
        </div>
    </React.Fragment>
    )
}

export default Header