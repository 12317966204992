import React, { Suspense, useEffect, useContext } from "react"
import { BlocksContext } from "../context/blocksContext"
import Header from "./header"
import { Menu } from "./menu"
import GetPresentation from "./get-presentation"
import Advantages from "./advantages"
import Plan from "./plan"
import Schema from "./schema"
import Otdelka from "./otdelka"
import HowToBuy from "./howToBuy"
import { Hod } from "./hod"
import { About } from "./about"
import Contacts from "./contacts"
import Footer from "./footer"


export const Loader = () => {
  

    const blocksImports = [
        <Menu />,
        <Header />,
        <GetPresentation />,
        <Advantages />,
        <Plan />,
        <Schema />,
        <Otdelka />,
        <HowToBuy />,
        <Hod />,
        <About />,
        <Contacts />,
        <Footer />,
    ]
    
    const loaded = useContext(BlocksContext)

    const LoadBlock = (block) => {
        return <Suspense fallback={<div>Загрузка...</div>}>{block}</Suspense>
    }

    const generateHtml = () => {
        let toDraw = []
        for (let i = 0; i < loaded.blocks; i++) {
            toDraw.push(LoadBlock(blocksImports[i]))
        }
        return (
            <div className="container_main">
                <div className="blocks" data={loaded.menuClick ? "true" : ""}>
                    {toDraw.map((block) =>
                        block
                    )}
                </div>
            </div>

        )
    }
    const handleScroll = (event) => {
        if (loaded.blocks < blocksImports.length) {
            loaded.setBlocks(blocksImports.length)
        } else {
            window.removeEventListener('scroll', handleScroll, true);
        }
    }
    useEffect(() => {
        if (loaded.blocks < blocksImports.length) {
            window.addEventListener('scroll', handleScroll, true);
        }
    })
    return generateHtml()

}