import React, { useState } from "react"
import { useSendForm } from "../hooks/send-from.hook"
import { PhoneInput } from "./phoneInput"
import SelectInput from "./selectInput"

export const Form = ({ fields, flat, hidden, formTitle, formClassName, btnClassName, celtype, btnTitle, template, callback, puForm, checkbox }) => {
  const sendForm = useSendForm()
  const [checked, setChecked] = useState(true)

  if (formClassName == "pu_ipoteka") {
    return <form action="" method="post" className={"pu_form"}>
      {formTitle ? <div className="bcc__form_title">{formTitle}</div> : <></>}
      <div className="pu_form_sort">
        {fields.map((field) => {
          switch (field.type) {
            case "textarea":
              return <textarea className={" ym-record-keys name"} type={field.type} name={field.name} placeholder={field.placeholder} req={field.required ? "y" : ""}></textarea>
            case "select":
              return <SelectInput field={field} />
            case "text":
              if (field.name == "phone") {
                return <PhoneInput />
              }
              return <input className={"in_style ym-record-keys name"} type={field.type} name={field.name} placeholder={field.placeholder} req={field.required ? "y" : ""} />
          }
        })}
      </div>
      <button className="btn_main" template={template} celtype={celtype} onClick={(e) => { sendForm.sendForm(e, callback); }}>{btnTitle}</button>
      <label className="check">
        <div className="check_inner">
          <input type="checkbox" checked={checked} onChange={(e) => { setChecked(e.target.checked) }} />
          <span>
            Нажимая “{btnTitle}”, вы даете согласие на обработку персональных данных и соглашаетесь с <a href="/docs/ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ЖК ОЛИМП.docx" download>политикой конфиденциальности</a>
          </span>
        </div>
      </label>
      <input type="hidden" className="text" value={hidden} />
    </form>
  }
  if (formClassName == "form_line") {
    return <React.Fragment>
      <div className="form_line">
        {fields.map((field) => {
          switch (field.type) {
            case "textarea":
              return <textarea className={" ym-record-keys name"} type={field.type} name={field.name} placeholder={field.placeholder} req={field.required ? "y" : ""}></textarea>
            case "select":
              return <SelectInput field={field} />
            case "text":
              if (field.name == "phone") {
                return <PhoneInput />
              }
              return <input className={"in_style ym-record-keys name"} type={field.type} name={field.name} placeholder={field.placeholder} req={field.required ? "y" : ""} />
          }
        })}
        <button className="btn_main" template={template} celtype={celtype} onClick={(e) => { sendForm.sendForm(e, callback); }}>{btnTitle}</button>

        <input type="hidden" className="text" value={hidden} />
      </div>
      <label className="check">
        <div className="check_inner">
          <input type="checkbox" checked={checked} onChange={(e) => { setChecked(e.target.checked) }} />
          <span>
            Нажимая “{btnTitle}”, вы даете согласие на обработку персональных данных и соглашаетесь с <a href="/docs/ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ЖК ОЛИМП.docx" download>политикой конфиденциальности</a>
          </span>
        </div>
      </label>
    </React.Fragment>
  }
  return (
    <form action="" method="post" className={formClassName && formClassName != "" ? formClassName : "pu_form"}>
      {flat ? <div class="pu_flat_info">
        <div>Общая площадь: {flat.area} м²</div>
        <div>Жилая площадь: {flat.living} м²</div>
      </div> : <></>}
      {formTitle ? <div className="bcc__form_title">{formTitle}</div> : <></>}
      {fields.map((field) => {
        switch (field.type) {
          case "textarea":
            return <textarea className={" ym-record-keys name"} type={field.type} name={field.name} placeholder={field.placeholder} req={field.required ? "y" : ""}></textarea>
          case "select":
            return <SelectInput field={field} />
          case "text":
            if (field.name == "phone") {
              return <PhoneInput />
            }
            return <input className={"in_style ym-record-keys name"} type={field.type} name={field.name} placeholder={field.placeholder} req={field.required ? "y" : ""} />
        }
      })}
      <button className="btn_main" template={template} celtype={celtype} onClick={(e) => { sendForm.sendForm(e, callback); }}>{btnTitle}</button>
      <label className="check">
        <div className="check_inner">
          <input type="checkbox" checked={checked} onChange={(e) => { setChecked(e.target.checked) }} />
          <span>
            Нажимая “{btnTitle}”, вы даете согласие на обработку персональных данных и соглашаетесь с <a href="/docs/ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ЖК ОЛИМП.docx" download>политикой конфиденциальности</a>
          </span>
        </div>
      </label>
      <input type="hidden" className="text" value={hidden} />
    </form>

  )
}