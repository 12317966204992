import { useState } from "react"

export const About = () => {
    const [showPopup, setShowPopup] = useState(false)

    return (<section className="about plr">
        <div className="wmain">
            <div className="tm">О застройщике</div>
            <div className="about_inner">
                <div className="about_l">
                    <img src="img/about_logo.png" />
                    <a target="_blank" className="btn_main" onClick={()=>{setShowPopup(true)}}>Документы по проекту</a>
                </div>
                <div className="about_r">
                    <p>
                        ГК «СТРОЙГРАД» - ОДИН ИЗ ВЕДУЩИХ ЗАСТРОЙЩИКОВ СТАВРОПОЛЯ. На рынке многоэтажного строительства с 2006 г. ГК «СТРОЙГРАД» располагает собственными производственными и складскими базами, автотранспортом и строительной техникой. Работы любой сложности ведутся быстро, качественно и без привлечения субподрядчиков.Со всей документацией по объекту вы можете ознакомиться на официальном сайте Единой информационной системы жилищного строительства <a href="https://наш.дом.рф/сервисы/каталог-новостроек/объект/59996" target="_blank">наш.дом.рф</a>
                    </p>
                </div>
            </div>
        </div>
        {
            showPopup?<div style={{"display": "block"}} className="pu_rgba plr">
                <div class="pu_table">
                    <div class="pu_cell">
                        <div style={{ "background": "url(img/pu_doc_bg.jpeg)", "display": "block" }} className="pu_inner pu_doc">
                            <div class="closeform" onClick={()=>{setShowPopup(false)}}>
                                <svg width="28.969727" height="28.309265" viewBox="0 0 28.9697 28.3093" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    <path id="Line 20" d="M2.66 2L26.96 26.3" stroke="#FFFFFF" stroke-opacity="1.000000" stroke-width="4.000000" stroke-linecap="round" />
                                    <path id="Line 21" d="M26.3 2L2 26.3" stroke="#FFFFFF" stroke-opacity="1.000000" stroke-width="4.000000" stroke-linecap="round" />
                                </svg>
                            </div>
                            <div class="pu_title">Документы<br />по проекту</div>
                            <form class="pu_form">
                                <div class="pu_doc_list">
                                    <a class="btn_main" href="/docs/Разрешение на строительство.pdf" download>Разрешение на строительство</a>
                                    <a class="btn_main" href="/docs/Проектная декларация.pdf" download>Проектная декларация</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>:<></>
        }
    </section>
    )
}