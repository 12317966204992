import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper'
import 'swiper/css';
import { useState } from 'react';

export const Advantages = () => {
    const [swiper, setSwiper] = useState(null)
    return (<section className="preim plr">
        <div className="wmain">
            <div className="tm">Преимущества ЖК</div>
            <div className="preim_slider_main">
                <Swiper
                    className='preim_slider'
                    modules={Navigation}
                    speed={400}
                    slidesPerView={1}
                    spaceBetween={150}
                    loop={false}
                    autoHeight={true}
                    onInit={(swiper) => { setSwiper(swiper) }}
                    breakpoints={{
                        1140: {
                            slidesPerView: 3,
                            spaceBetween: 10,
                        },
                        780: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                    }}
                >
                    <SwiperSlide>
                        <div className="preim_slide">
                            <div className="preim_slide_l">
                                <div style={{ "background": "url(img/preim_img1.jpeg)" }} className="preim_slide_l_top">
                                    <span>Детские <br />и спортивные <br />площадки</span>
                                </div>
                                <div className="preim_slide_l_bottom">
                                    <span>Дизайнерские <br />входные группы</span>
                                </div>
                            </div>
                            <div style={{ "background": "url(img/preim_img1_2.jpeg)" }} className="preim_slide_r">
                                <span>Природный<br /> заказник<br /> и река в пешей<br /> доступности</span>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="preim_slide ps_2">
                            <div className="preim_slide_l">
                                <div className="preim_slide_l_bottom">
                                    <span>Закрытая <br />территория</span>
                                </div>
                                <div style={{ "background": "url(img/preim_img2.jpeg)" }} className="preim_slide_l_top">
                                    <span>Наземная <br />придомовая <br />парковка</span>
                                </div>
                            </div>
                            <div className="preim_slide_r">
                                <div style={{ "background": "url(img/preim_img2_2.jpeg)" }} className="preim_slide_l_top">
                                    <span>Доступная<br /> социальная<br /> инфраструктура</span>
                                </div>
                                <div className="preim_slide_l_bottom">
                                    <span>Теплые <br />полы</span>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="preim_slide ps_3">
                            <div style={{ "background": "url(img/preim_img3.jpeg)" }} className="preim_slide_r">
                                <span>Наличие <br />коммерческих <br />помещений</span>
                            </div>
                            <div className="preim_slide_l">
                                <div className="preim_slide_l_bottom">
                                    <span>Видео-<br />наблюдение</span>
                                </div>
                                <div style={{ "background": "url(img/preim_img3_2.jpeg)" }} className="preim_slide_l_top">
                                    <span>Наличие<br /> пассажирских<br /> и грузовых<br /> лифтов</span>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <div className="preim_slider_nav">
                        <div className="swiper-button-prev" onClick={()=>{swiper.slidePrev(); setShowArrow()}}>
                            <svg width="66.008057" height="26.643555" viewBox="0 0 66.0081 26.6436" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <path id="Arrow 4" d="M6.83 11.32L14.73 3.42C15.52 2.63 15.52 1.38 14.73 0.59C13.94 -0.2 12.69 -0.2 11.9 0.59L0.59 11.9C-0.2 12.69 -0.2 13.94 0.59 14.73L11.9 26.04C12.69 26.84 13.94 26.84 14.73 26.04C15.52 25.25 15.52 24.01 14.73 23.22L6.83 15.32L64 15.32C65.12 15.32 66 14.44 66 13.32C66 12.2 65.12 11.32 64 11.32L6.83 11.32Z" fill="#1E1E1E" fill-opacity="1.000000" fill-rule="evenodd" />
                            </svg>
                        </div>
                        <div className="swiper-button-next" onClick={()=>{swiper.slideNext(); setShowArrow()}}>
                            <svg width="66.008057" height="26.643555" viewBox="0 0 66.0081 26.6436" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <path id="Arrow 3" d="M59.17 11.32L51.27 3.42C50.48 2.63 50.48 1.38 51.27 0.59C52.06 -0.2 53.3 -0.2 54.1 0.59L65.41 11.9C66.2 12.69 66.2 13.94 65.41 14.73L54.1 26.04C53.3 26.84 52.06 26.84 51.27 26.04C50.48 25.25 50.48 24.01 51.27 23.22L59.17 15.32L2 15.32C0.87 15.32 0 14.44 0 13.32C0 12.2 0.87 11.32 2 11.32L59.17 11.32Z" fill="#1E1E1E" fill-opacity="1.000000" fill-rule="evenodd" />
                            </svg>
                        </div>
                    </div>
                </Swiper>

            </div>
        </div>
    </section>

    )
}

export default Advantages