export const Schema = () => {
    return (<section className="shema plr">
        <div className="wmain">
            <div className="tm">Схема проезда</div>
            <div className="shema_content">
                <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A1d5b5f24c06d69b328242b63c5cd5acf502634e99e1073c03d70e4f61c937319&amp;source=constructor" width="100%" height="297" frameborder="0"></iframe>
            </div>
            <div className="shema_bottom_text">
                Возможность быстрого выезда на Пятигорск
            </div>
        </div>
    </section>
    )
}

export default Schema