import { useEffect } from "react"

export const useSendForm = () => {
    const addError = (element) => {
        element.classList.add('err')
    }
    const removeError = (element) => {
        element.classList.remove('err')
    }
    const checkPhone = (element) => {
        if (element.value.indexOf('_') !== -1 || element.value.length !== 18 || isFakePhone(element.value)) {
            addError(element)
            return false
        }
        removeError(element)
        return true
    }

    const checkEmail = (element) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(element.value).toLowerCase())) {
            addError(element)
            return false
        } else {
            removeError(element)
            return true
        }

    }
    const getUtms = (paths) => {
        let utm = {}
        if (paths.split('&').length > 0) {
            let params = paths.split('&')
            params.forEach((param) => {
                param = param.split('=')
                if (param[0] === "utm_medium" || param[0] === "utm_content" || param[0] === "utm_campaign" || param[0] === "utm_term" || param[0] === "utm_source") {
                    utm = { ...utm, [param[0]]: param[1] }
                }
            })
        }
        return utm
    }

    const getYandexClientId = () => {
        window.site = {}
        site.clientID = 0
        try {
            setTimeout(() => {
                let metriks = parseInt(Object.keys(window.Ya._metrika.counters)[0].split(':')[0])
                ym(metriks, 'getClientID', function (clientID) {
                   
                    site.clientID = parseInt(clientID)
                    console.log('yandex client id = '+clientID)
                })
            }, 7000)
        } catch (e) {
            console.log('error getting yandex client id')
        }
    }

    const getParam = (p) => {
        var match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    }




    const showAlert = (data, celtype, sendCell) => {
        if (window.Ya !== undefined && sendCell) {
            let metriks = parseInt(Object.keys(window.Ya._metrika.counters)[0].split(':')[0])
            try {
                ym(metriks, 'reachGoal', celtype);
                ym(metriks, 'reachGoal', 'vse');
            } catch (e) { }
        }
        try {
            gtag('event', 'send', {
                'event_category': 'forms',
                'event_label': celtype,
                'value': 1
            });
            gtag('event', 'send', {
                'event_category': 'forms',
                'event_label': 'vse',
                'value': 1
            });
        } catch (e) { }
        try {
            fbq('trackCustom', 'vse');
        } catch (e) { }
        try {
            VK.Goal('lead')
        } catch (e) { }
    }

    const sendForm = (e, callback) => {
        e.preventDefault()
        let flag = true
        let form = e.target.closest('form')
        let celtype = e.currentTarget.getAttribute('celtype')
        let name = form.querySelector('input[name="name"]') !== null ? form.querySelector('input[name="name"]').value : ''
        let phone = form.querySelector('input[name="phone"]').value
        let url = window.location.toString().split("?")
        let email
        
        if (form.querySelector('input[name="email"]') !== null) {
            email = form.querySelector('input[name="email"]').value
        }
        let utm = null
        if (url.length > 1) {
            utm = getUtms(url[1])
        }
        if (!checkPhone(form.querySelector('input[name="phone"]'))) {
            flag = false
        }

        /*if (form.querySelector('input[name="name"]') !== null) {
            if (form.querySelector('input[name="name"]').value === "") {
                flag = false
                addError(form.querySelector('input[name="name"]'))
            } else {
                removeError(form.querySelector('input[name="name"]'))
            }
        }*/

        if (form.querySelector('input[name="email"]') !== null && form.querySelector('input[name="email"]').value !== "") {
            if (!checkEmail(form.querySelector('input[name="email"]'))) {
                flag = false
            }
        }
        let text = form.querySelector('input.text').value
        form.querySelectorAll('.dop-info').forEach((el) => {
            if (el.value.length !== 0 && el.getAttribute('data') !== null)
                text += ',' + el.getAttribute('data') + ':' + el.value
        })
        form.querySelectorAll('[name="dop-info"]').forEach((el) => {
            if (el.value.length !== 0)
                text += el.getAttribute('data') + ':' + el.value
        })
        form.querySelectorAll('.dop-select').forEach((el) => {
            if (el.value.length !== 0 && el.getAttribute('data')!== el.value)
                text += ', ' + el.getAttribute('data') + ":" + el.value
        })
        

        if (flag) {
            let senddata = { getCall: "Y", celtype: celtype, name: name, phone: phone, text: text, yclid: getParam('yclid'), gclid: getParam('gclid'), yandexClientId: site.clientID, roistat: getCookieByName("roistat_visit"), ...utm }
            if (email !== undefined) {
                senddata = { ...senddata, email: email }
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(senddata)
            };
            document.querySelectorAll('.pu_inner').forEach((elem) => {
                elem.style.display = "none"
            })
            
            console.log(senddata)
            callback()
          
            fetch("https://integrat.leadactiv.ru/lead/site", requestOptions)
                .then((response) => response.json())
                .then(data => {
                    showAlert(senddata, celtype, !data.repeat)
                });
        }
    }

    useEffect(()=>{
        getYandexClientId()
    },[])

    return { sendForm }
}

const isFakePhone = (phone) => {
    phone = phone.replace(/[^0-9]/g,'')
    let code = phone.substring(1,4)
    if (code[0] !== '9') {
        return true
    }
    let repeatnes = 0
    for (let i=0; i < phone.length; i++) {
        if (repeatnes >= 6) {
            return true
        }

        if (phone[i] === phone[i+1]){
            repeatnes++
            continue
        }
        repeatnes = 0
    }
    return false
}

const getCookieByName = (name) => {
    function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
    var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
    return match ? match[1] : "";
}